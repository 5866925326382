* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #e2e8f0;
  color: #060606;
  font-family: "cutive mono", sans-serif;
  padding: 30px;
}

.hidden {
  display: none !important;
}

/* Main Layout */
#root {
  max-width: 1000px;
  margin: 0 auto;
}

.header {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* Elements */
.logo {
  display: flex;
  align-items: center;
}

.logo img {
  width: 68px;
  height: 68px;
}

.main {
  display: grid;
  grid-template-columns: 1fr;
}

h1 {
  font-size: 30px;
  font-family: inherit;
  line-height: 1;
}

.sport-filter {
  background-color: #cbd5e1;
  border-radius: 10px;
  margin-bottom: 16px;
  padding: 16px 24px;
  text-align: center;
}
.text {
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 16px;
  font-family: "cutive mono", sans-serif;
}

.fact {
  background-color: #cbd5e1;
  border-radius: 10px;
  font-size: 16px;
  line-height: 1.4;
  margin-bottom: 16px;
  padding: 16px 24px;

  margin-right: auto;
  display: flex;
  flex-shrink: 0;
  align-items: center;
}

@media (max-width: 1200px) {
  .fact {
    flex-direction: column;
    align-items: flex-end;
    gap: 12px;
  }
}

.vote-buttons {
  margin-left: auto;
  flex-shrink: 0;

  display: flex;
  gap: 8px;
}
.vote-buttons button {
  font-family: inherit;
  font-size: 14px;
  background-color: #e2e8f0;
  color: #1e293b;
  border-radius: 10px;
  border: none;
  padding: 15px;
  cursor: pointer;
}
.vote-buttons button:hover {
  scale: 90%;
}

.vote-buttons button:disabled {
  background-color: #cbd5e1;
}

.vote-buttons p {
  font-family: inherit;
  font-size: 14px;
  border-radius: 10px;
  border: none;
  padding: 15px;
}

ul {
  list-style: none;
}

.source:link,
.source:visited {
  color: #64748b;
  text-decoration: none;
  transition: 0.3s;
}

.source:hover,
.source:active {
  color: #334155;
}

.bookie:link,
.bookie:visited {
  color: #64748b;
  text-decoration: none;
  transition: 0.3s;
}

.bookie:hover,
.bookie:active {
  color: #334155;
}

.btn {
  font-family: inherit;
  line-height: 1;
  font-size: 14px;
  padding: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #1e293b;
  border: none;
  border-radius: 10px;
  color: #f1f5f9;
  transition: 0.3s;
}

.btn:hover {
  transform: scale(90%);
  cursor: pointer;
}

.btn-sport {
  font-family: inherit;
  line-height: 1;
  font-size: 14px;
  text-transform: uppercase;
  padding: 15px;
  margin: 5px;
  background-color: #1e293b;
  border: none;
  border-radius: 10px;
  color: #f1f5f9;
  transition: 0.3s;
}

.btn-sport:hover {
  transform: scale(90%);
  cursor: pointer;
}

/* FORM */
.fact-form {
  background-color: #cbd5e1;
  margin-bottom: 40px;
  padding: 16px 32px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 10px;
}

.fact-form input,
.fact-form select {
  width: 220px;
  background-color: #f1f5f9;
  border: none;
  border-radius: 10px;
  padding: 16px;
  font-size: 12px;
  color: inherit;
  font-family: inherit;
}

.fact-form input::placeholder {
  color: #1e293b;
}

.fact-form input:first-child {
  flex-grow: 1;
}

.fact-form span {
  font-size: 16px;
  margin-right: 18px;
}

@media (max-width: 1000px) {
  body {
    padding: 16px 24px;
  }

  .fact-form {
    flex-direction: column;
    align-items: stretch;
  }

  .fact-form input,
  .fact-form select {
    width: auto;
  }
}
